/**
 *
 */
export default function clearNonValidGroupBy() {
    const activeColumns = this.dataGet('reportWindow/columns/active');
    const hiddenColumns = this.dataGet('reportWindow/columns/hidden');
    const groupByObjects = this.dataGet('reportWindow/groupBy');

    const groupMap = new Map();

    groupByObjects.forEach((value, key) => {
        if (activeColumns.some((column) => column.name === value.field)) {
            groupMap.set(key, value);
        }
        if (hiddenColumns.some((column) => column.name === value.field)) {
            groupMap.set(key, value);
        }
    });

    this.dataSet('reportWindow/groupBy', groupMap);
}
