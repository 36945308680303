/**
 *
 */
export default async function routeLogin() {
    this.load();
    await this.rest.fetchData('getLoginData', 'GET', null).then((response) => {
        this.dsClear();
        this.dsAdd('set', 'conf/system/systemName', response.data['systemName']);
        this.dsAdd('set', 'conf/system/logo', response.data['logo']);
        this.dsAdd('set', 'conf/system/headerLogo', response.data['headerLogo']);
        this.dsAdd('set', 'conf/language', response.data['defaultLanguage']);
        this.dsAdd('set', 'conf/enableServis', response.data['enableServis']);
        this.dsProcess();
    });
    this.rest.get('trns?language='+this.getLanguage(), (data) => {
        this.rest.setCookie('sID', '', -1);
        this.dataSet('menu/groups', []);
        clearInterval(this.notificationInterval);

        this.app_strings = data.lang;
        this.dsClear();
        this.dsAdd('set', 'conf/load', false);
        this.dsAdd('set', 'conf/front_version', data.softwareInfo.front_version);
        this.dsAdd('set', 'conf/back_version', data.softwareInfo.back_version);
        this.dsAdd('set', 'conf/softwareName', data.softwareInfo.softwareName);
        // TODO Nastav store
        this.dsAdd('set', 'conf/view', 'login');
        this.dsProcess();
        this.renderReact();
    }, false);
}
