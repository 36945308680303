import React from 'react';
import PureComponent from '../pure';
import sAction from 'sAction';
import Select from '../formElements/Select';

export default class ImportFileSelect extends PureComponent {
    constructor(props) {
        super(props);
    }

    onChange = (e) => {
        this.props.onChange(e.target.value);
    };

    render() {
        const options = this.props.options;
        const value = this.props.value;
        const label = this.props.label;
        let select;

        if (options !== undefined) {
            select = (
                <Select
                    options={options}
                    containerClassName="withBorder"
                    defaultValue={value}
                    allowSearchInValues={true}
                    onChange={this.onChange}
                />
            );
        }

        return (
            <div>
                <div className="importViewSelectorLabel">
                    {label}
                </div>
                <div className="importViewSelectorContent">
                    {select}
                </div>
            </div>
        );
    }
}
