/**
 *
 * @param value
 */
export default function findAlterName(value) {
    const alterNames = this.dataGet('reportWindow/alterName').toJS();

    for (const alterName of Object.values(alterNames)) {
        if (alterName.field.toUpperCase() === value.key && alterName.vname) {
            return alterName.vname;
        }
    }
    return this.translate(value.label, value.def.get('fieldModule'));
}
