import React from 'react';
import MaterialDefault from './MaterialDefault';
import SelectWindow from './SelectWindow';

import sAction from 'sAction';
import ReactDOM from 'react-dom';

export default class TabSelect extends MaterialDefault {
    constructor(props) {
        super(props);

        let label = null;
        this.props.options.forEachObject((obj, key) => {
            obj.forEach((line, lineKey) => {
                if (line.value == this.props.defaultValue) {
                    label = line.label;
                }
            });
        });

        this.state = {
            focus: false,
            openOnFocus: true,
            open: this.props.open ? this.props.open : false,
            label: label,
        };

        this.ref = React.createRef();
    }

    /**
     *
     * @param params
     */
    onChange(params) {
        this.setState({
            value: params.value,
            open: false,
            label: params.label,
            index: params.index,
        });
        params.numOfTabSelect = this.props.numOfTabSelect; // in case we have multiple tab selects and need to differentiate which changed value
        if (this.props.onChange != undefined) {
            this.props.onChange(params);
        }
    }

    onClose = (e) => {
        this.setState({open: false});
        if (this.props.onClose != undefined) {
            this.props.onClose(e);
        }
    };

    /**
     *
     * @param e
     */
    onOpen(e) {
        this.setState({open: true});
        if (this.props.onOpen != undefined) {
            this.props.onOpen(e);
        }
    }

    /**
     *
     * @param e
     */
    onBlur(e) {
        if (this.props.onBlur != undefined) {
            this.props.onBlur(e);
        }
    }

    /**
     *
     * @param e
     */
    onKeyDown(e) {
        if (e.keyCode === 13 && this.state.open === false) {
            this.setState({open: true});
        }

        if (this.props.onKeyDown != undefined) {
            this.props.onKeyDown(e);
        }
    }

    toggle = (e) => {
        if (this.props.disabled && !this.state.open) {
            return;
        }
        this.setState({open: !this.state.open});
    };

    onClick = (e) => {
        if (!this.state.open) {
            this.setState({open: true});
        }
    };

    /**
     *
     * @param e
     */
    onFocus(e) {
        if (this.props.onFocus !== undefined) {
            this.props.onFocus(e);
        }
    }

    getElement = () => {
        return this.ref;
    };

    checkNode = (e) => {
        return ReactDOM.findDOMNode(this)?.contains?.(e);
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.clearLabel === true) {
            this.setState({label: null});
        }
    }

    render() {
        const options = this.props.options;

        let className = 'acmSelectContainer tabSelect';
        if (this.state.focus == true) {
            className += ' focus';
        }
        if (this.props.error == true) {
            className += ' error';
        }

        let container = document.getElementById(this.props.container);
        let isPopupOpen = null;
        if (sAction.getNewPopupId()) {
            // todo parent should provide element name, hopefully fix this in future
            container = document.getElementsByClassName('acmPopup popupShow')[0];
            isPopupOpen = true;
        }

        const selectWindow = (
            <SelectWindow
                className="acmSelect"
                id={this.props.id}
                inputProps={{id: this.props.id}}
                onChange={(e) => this.onChange(e)}
                onOpen={(e) => this.onOpen(e)}
                onClose={this.onClose}
                onBlur={(e) => this.onBlur(e)}
                onFocus={(e) => this.onFocus(e)}
                onClick={(e) => this.onChange(e)}
                toggle={this.toggle}
                inputRef={this.props.myRef}
                open={this.state.open}
                autoFocus={this.props.autoFocus}
                name={this.props.name}
                options={options}
                container={container}
                isPopupOpen={isPopupOpen}
                allowSearchInValues={this.props.allowSearchInValues ?? false}
                getParentElement={this.getElement}
                defaultValue={this.props.defaultValue}
                activeTabName={this.props.activeTabName || ''}
                checkNode={this.checkNode}
            />
        );

        return (
            <>
                <div
                    className={`${className} ${this.props.containerClassName != null ? this.props.containerClassName : ''}`.trim()}
                    style={this.props?.containerStyle}
                    onKeyDown={(e) => this.onKeyDown(e)}
                    onClick={this.toggle}
                    autoFocus={this.props.autoFocus}
                    ref={this.ref}
                >
                    <div className="selectLabel" style={this.props.labelStyle}>
                        {this.state.label ?? this.props.label}
                    </div>
                    <div style={{flex: '0 0 auto'}}>
                        {this.state.open ? (
                            <svg className="MuiSelect-iconOpen" style={{
                                width: '24px',
                                height: '24px',
                            }}>
                                <path d="M7 10l5 5 5-5z"></path>
                            </svg>
                        ) : (
                            <svg className="" focusable="false" style={{
                                width: '24px',
                                height: '24px',
                            }}>
                                <path d="M7 10l5 5 5-5z"/>
                            </svg>
                        )}
                    </div>
                </div>
                {this.state.open && selectWindow}
            </>
        );
    }
}
