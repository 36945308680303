import detailDefault from '../../detailDefault';

/* eslint-disable camelcase */
export default class detailacm_projects extends detailDefault {
    load(sAction, data) {
        this.setMarginType(sAction, data.prefix);
    }

    update(sAction, data) {
        switch (data.field) {
            case 'margin_type':
                this.setMarginType(sAction, data.prefix);
                break;
            case 'margin':
            case 'margin_percent':
            case 'estimated_price':
                this.calculateMargin(sAction, data.prefix);
                break;
        }
    }

    /**
     * set the correct margin field for the margin type for DAKO
     * @param {sAction} sAction
     * @param {string} prefix
     */
    setMarginType(sAction, prefix) {
        const marginField = sAction.dataGet(`${prefix}/fields/margin_type/value`) === 'percentage' ? 'margin_percent' : 'margin';
        sAction.dataGet(`${prefix}/tabs`).map((tab, tabIdx) => {
            tab.panels.map((panel, panelIdx) => {
                panel.get('rows').map((row, rowIdx) => {
                    row.fields.map((field, fieldIdx) => {
                        if (['margin_percent', 'margin'].includes(field.get('name'))) {
                            sAction.dataSet(
                                `${prefix}/tabs/${tabIdx}/panels/${panelIdx}/rows/${rowIdx}/fields/${fieldIdx}/name`,
                                marginField,
                            );
                        }
                    });
                });
            });
        });
    }

    /**
     * Calculate margin based on the margin type
     * @param {Object} sAction
     * @param {string} prefix
     */
    calculateMargin(sAction, prefix) {
        const amount = sAction.dataGet(`${prefix}/fields/estimated_price/value`);
        const marginType = sAction.dataGet(`${prefix}/fields/margin_type/value`);
        const margin = sAction.dataGet(`${prefix}/fields/margin/value`);
        const marginPercent = sAction.dataGet(`${prefix}/fields/margin_percent/value`);

        sAction.dsClear();
        if (marginType === 'percentage') {
            const calculatedMargin = (amount * marginPercent) / 100;
            sAction.dsAdd('set', `${prefix}/fields/margin/value`, calculatedMargin);
            sAction.dsAdd('set', `${prefix}/changes/fields/margin`, calculatedMargin);
        } else if (amount > 0) {
            const calculatedMarginPercent = (margin / amount) * 100;
            sAction.dsAdd('set', `${prefix}/fields/margin_percent/value`, calculatedMarginPercent);
            sAction.dsAdd('set', `${prefix}/changes/fields/margin_percent`, calculatedMarginPercent);
        }
        sAction.dsProcess();
    }
}
