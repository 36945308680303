import React from 'react';
import PropTypes from 'prop-types';
import InputField from '../inputFieldClass';
import sAction from '../../../action';
import InputText from '../../formElements/InputText';

export default class DefaultFloat extends InputField {
    constructor() {
        super();
        this.dec_sep = sAction.userPreferences.dec_sep || ',';
        this.precision = sAction.userPreferences.currency_significant_digits || 2;
        this.state = {
            state: 'fine',
            reg: /^-?\d*\.?\d+$/,
        };
    }

    /**
     * @return {string|*}
     */
    getValue() {
        let value = this.input.current.value;
        if (typeof value === 'string') {
            value = value.replace(this.dec_sep, '.');
        }

        return value;
    }

    render() {
        const newRecord = this.props.newRecord;
        const displayType = this.props.def?.get('displayType');
        const defPrcision = this.props.def?.get('precision');
        const ok = this.state.ok;
        if (this.props.name === 'conversion_rate') {
            this.precision = 6;
        }
        // var inputClass = "form-control form-control-sm";
        const containerClass = 'inputEditContainer';
        let error = false;
        if (ok === false) {
            //  inputClass += " wrongInput";
            error = true;
        }

        let value = this.props.value;
        if (!value) {
            value = '0';
        }
        if (value) {
            value = parseFloat(value);
            value = value.toFixed(displayType?defPrcision:this.precision);
            value = value.toString();
        }
        if (typeof value === 'string') {
            value = value.replace('.', this.dec_sep);
        }
        if (newRecord && !value) {
            value = '';
        }
        if (displayType) {
            value = sAction.formatNumberToHours(value, displayType);
            this.state.reg = undefined;
        }

        return (
            <div className={containerClass} data-fieldname={this.props.name}>
                <div className="inputContainer">
                    <InputText
                        autoFocus={true}
                        onKeyUp={(e) => this.keyUpCall(e)}
                        onKeyDown={(e) => this.onKeyDown(e)}
                        myRef={this.input}
                        onBlur={(e) => this.onBlur(e)}
                        error={error}
                        defaultValue={value}
                        id={this.props.name}
                        name={this.props.name}
                        fieldtype={this.props.def.get('type')}
                    />
                </div>
                {!newRecord && (
                    <div className="buttonContainer">
                        <div
                            tabIndex="2"
                            onClick={() => this.save()}
                            className="inputEditButton"
                        >
                            <div className={'icon-detailConfrim'} />
                        </div>
                        <div
                            tabIndex="3"
                            onClick={() => this.cancel()}
                            className=" inputEditButton"
                        >
                            <div className={'icon-detailCancel'} />
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

DefaultFloat.propTYpes = {
    newRecord: PropTypes.bool,
    def: PropTypes.object,
    value: PropTypes.number,
    name: PropTypes.string.isRequired,
};
