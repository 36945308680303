import {List} from 'immutable';

/**
 *
 */
export default function routeImport() {
    const module = this.getModuleName();
    const prefix = 'view';
    this.load();

    // reload on save in RP while displaying import results so we just fetch updated data
    if (sessionStorage.getItem('listImported') === 'true') {
        const action = this.getStorage('prepareListStructure');
        const view = this.getViewName();
        const filterData = this.getStorage('listFilterData');

        const reloadData = filterData !== null ? filterData[view+module] : null;
        action.offset = reloadData.offset;
        action.actPagination = reloadData.actPagination;
        action.filter = List(reloadData.filter);
        action.actFiltering = reloadData.actFiltering;

        const recordsPerPage = this.dataGet('conf/user/records_per_page');
        action.limit = recordsPerPage != null ? recordsPerPage : 25;
        const importList = this.prepareListStructure(action);
        this.reloadList(0, 1, prefix, null, null, importList);
    } else {
        this.rest.get('importDefs/' + module, (data) => {
            const fields = {
                fields: [],
                relate: [],
            };
            data.fields.forEachObject((field, key) => {
                if (field != null) {
                    if (field.type == 'relate' || field.type == 'link') {
                        if (field.searchable == true) {
                            fields.relate.push(field);
                        }
                    } else {
                        fields.fields.push(field);
                    }
                }
            });
            this.dsAdd('set', prefix, {});
            this.dsAdd('set', prefix + '/fields', fields);
            this.dsAdd('set', prefix + '/mapping/additionalData', []);
            this.dsAdd('set', prefix + '/mappedRelates', []);
            this.dsAdd('set', prefix + '/buttons', []);
            this.dsAdd('set', prefix + '/module', module);
            this.dsAdd('set', prefix + '/delimiters', {});
            this.dsAdd('set', prefix + '/delimiters/lineDelimiter', '\n');
            this.dsAdd('set', prefix + '/delimiters/columnDelimiter', ';');
            this.dsAdd('set', prefix + '/fileEncoding', 'UTF-8');
            this.dsAdd('set', prefix + '/duplicates/field', '');
            this.dsAdd('set', prefix + '/duplicates/action', 'ignore');
            data.menu.forEach((button) => {
                this.dsAdd('add', prefix + '/buttons', {id: button.id, def: button}, 'DetailButton');
            });

            this.dsAdd('set', 'conf/load', false);
            this.dsProcess();
        });
    }
}
