/**
 * @this sAction
 * @button DetailView/Users
 */
export default function usrMsgraphSignIn() {
    this.load();
    this.rest.post('msgraphSignIn', {}, (ret) => {
        this.unLoad();
        if (ret.state) {
            window.open(ret.redirect_url, '_blank');
            window.close();
        }
    });
}
