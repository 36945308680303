import detailDefault from '../../detailDefault';

// eslint-disable-next-line camelcase
export default class detailacm_projects_accounts extends detailDefault {
    load(sAction, data) {
        const account = sAction.dataGet(data.prefix + '/fields/accounts_acm_projects_accounts_1_name/value');
        const project = sAction.dataGet(data.prefix + '/fields/acm_projects_acm_projects_accounts_1_name/value');
        if (account && project) {
            sAction.dataSet(data.prefix + '/fields/acm_projects_acm_projects_accounts_1_name/def/readonly', true);
            return;
        }
        if (account) {
            data.value = {
                name: account,
                id: sAction.dataGet(data.prefix + '/fields/accounts_acm_projects_accounts_1_name/def/id_value'),
            };
            this.loadAccountData(sAction, data);
            sAction.dataSet(data.prefix + '/fields/accounts_acm_projects_accounts_1_name/def/readonly', true);
        }
        if (project) {
            data.value = {
                name: project,
                id: sAction.dataGet(data.prefix + '/fields/acm_projects_acm_projects_accounts_1_name/def/id_value'),
            };
            this.loadProjectData(sAction, data);
            sAction.dataSet(data.prefix + '/fields/acm_projects_acm_projects_accounts_1_name/def/readonly', true);
        }
    }

    update(sAction, data) {
        switch (data.field) {
            case 'accounts_acm_projects_accounts_1accounts_ida':
                this.loadAccountData(sAction, data);
                break;
            case 'acm_projects_acm_projects_accounts_1acm_projects_ida':
                this.loadProjectData(sAction, data);
                break;
        }
    }

    /**
     * fetch data from related account
     * @param {sAction} sAction
     * @param {object} data
     */
    loadAccountData(sAction, data) {
        if (!data.value.id) {
            return;
        }

        const fields = {
            name: 'name',
            ticker_symbol: 'ticker_symbol',
            account_type: 'account_type',
            industry: 'industry',
            website: 'website',
        };

        const sendData = {
            module: 'Accounts',
            id: data.value.id,
            fields: Object.keys(fields),
            action: 'getBeanInfo',
        };

        sAction.load();
        sAction.rest.fetchData('customAction', 'POST', sendData, false).then((response) => {
            sAction.dsClear();
            for (const [key, value] of Object.entries(response)) {
                sAction.dsAdd('set', data.prefix + '/fields/' + fields[key] + '/value', value);
                sAction.dsAdd('set', data.prefix + '/changes/fields/' + fields[key], value);
            }

            sAction.dsProcess();
            sAction.unLoad();
        });
    }

    /**
     * fetch data from related project
     * @param {sAction} sAction
     * @param {object} data
     */
    loadProjectData(sAction, data) {
        if (!data.value.id) {
            return;
        }

        const fields = {
            phase: 'phase',
            area_m2: 'area_m2',
            estimated_price: 'estimated_price',
            currency_id: 'currency_id',
            realisation_year: 'realisation_year',
            quarter: 'quarter',
        };

        const sendData = {
            module: 'acm_projects',
            id: data.value.id,
            fields: Object.keys(fields),
            action: 'getBeanInfo',
        };

        sAction.load();
        sAction.rest.fetchData('customAction', 'POST', sendData, false).then((response) => {
            sAction.dsClear();
            for (const [key, value] of Object.entries(response)) {
                sAction.dsAdd('set', data.prefix + '/fields/' + fields[key] + '/value', value);
                sAction.dsAdd('set', data.prefix + '/changes/fields/' + fields[key], value);
            }

            sAction.dsProcess();
            sAction.unLoad();
        });
    }
}
